.app-header-container{
    width: 100%;
    background-color: #161524;
    padding: 0 1rem;

    .app-menu{
        background-color: #161524;
        width: 80%;
        height: 100%;
        position: absolute;
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 1rem;
        box-shadow: 2px 0px 10px 2px rgba(0,0,0,0.4);
        z-index: 1;

        @media (min-width: 1024px) {
            display: none;
        }

        .hamburger-menu-items{
            display: grid;
            grid-auto-flow: row;
            padding: 1rem;
            color: #FFFFFF;
            align-items: center;
            grid-auto-rows: 3rem;
            width: 100%;

            .menu-item{
                text-transform: capitalize;
                height: 100%;
                width: 80%;
                align-content: center;
                display: grid;
                padding: 0 1rem;
                border-radius: 0.25rem;
                grid-template-columns: max-content max-content max-content;
                grid-gap: 0.25rem;

                .open-close-menu-icon{
                    transform: rotate(-90deg);
                }
                
                &:hover{
                    border-bottom: thin solid #ffffff;
                    border-radius: unset;
                    
                }
            }
        }
    }

    .app-header{
        background-color: #161524;
        display: grid;
        grid-template-columns: max-content max-content auto max-content max-content;
        grid-auto-flow: column;
        height: 3.5rem;
        align-items: center;
        padding: 0 1rem;
        column-gap: 1rem;
        grid-template-areas: 'hamburger logo menu search alerts';

        @media (min-width: 1024px) {
            height: 100%;
            max-width: 73.5rem;
            margin: 0 auto;
            padding: 0;
        }

        nav{
            display: none;

            @media (min-width: 1024px) {
                display: grid;
                grid-auto-flow: column;
                color: #ffffff;
                column-gap: 1.5rem;
                align-items: center;
                text-transform: capitalize;
                grid-area: menu;
                width: max-content;
                padding-left: 2.5rem;
            }
            .menu-item{
                display: grid;
                place-items: center;
                grid-auto-flow: column;
                cursor: pointer;
                grid-gap: 0.25rem;
            }
        }

        .hamburger-menu-icon{
            grid-area: hamburger;
            cursor: pointer;
            user-select: none;

            @media (min-width: 1024px) {
                display: none;
            }
        }
        .app-logo{
            grid-area: logo;
            cursor: pointer;
            user-select: none;

            @media (min-width: 1024px) {
                grid-column: 1;
            }
        }

        .search-icon{
            grid-area: search;
            cursor: pointer;
            user-select: none;
        }
        .alert-icon{
            grid-area: alerts;
            cursor: pointer;
            user-select: none;
        }
    }
}
