.try-now-visualizer-container {
  background-image: url("../../assets/svg/sample.svg");
  background-size: cover;
  background-position: center;
  position: relative;
  background-origin: content-box;
  background-repeat: no-repeat;

  @media (min-width: 425px) {
    width: 23.75rem;
    height: 31.25rem;
    justify-self: center;
    border-radius: 1rem;
  }

  .logo-container {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(0.25rem);
    border-radius: 1rem;

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      animation: loading 3s linear infinite;

      @keyframes loading {
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .flash {
    background-color: #ffffff;
    opacity: 0.8;
    height: 100%;
    width: 100%;
    position: absolute;
    animation: 0.5s linear;
    z-index: 1;
    display: none;

    @keyframes flash {
      0% {
        opacity: 0.5;
      }
      5% {
        opacity: 1;
      }
      10% {
        opacity: 0.3;
      }
      20% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  .try-now-visualizer {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(8px);
    display: grid;
    grid-template-rows: auto max-content auto;
    align-items: center;
    justify-items: center;
    position: relative;

    @media (min-width: 425px) {
      border-radius: 1rem;
      overflow: hidden;
    }

    h2 {
      margin: 0;
      padding: 0;
      line-height: 1.25rem;
      font-size: 2rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #ffffff;
    }

    .option-selector {
      display: grid;
      grid-auto-flow: row;
      row-gap: 1.5rem;
    }

    .caption {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      color: #ffffff;
      column-gap: 0.5rem;
      align-self: end;
      padding-bottom: 0.8rem;
      grid-row: 3;
      position: absolute;
      bottom: -0.7rem;
      user-select: none;

      @media (min-width: 425px) {
        bottom: 0;
      }
    }

    .selected-screen-header {
      display: grid;
      grid-template-columns: auto 1fr max-content;
      width: 100%;
      padding: 0 0.81rem;
      color: #ffffff;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.25rem;
      align-items: center;
      position: absolute;
      top: 0.56rem;
      z-index: 1;

      span {
        text-transform: uppercase;
        grid-column: 2;
      }

      svg {
        cursor: pointer;
      }
    }

    .try-now-camera-viewer {
      position: absolute;
      height: 100%;
      width: 100%;

      canvas {
        object-fit: cover;
        height: 100%;
        width: 100%;
        visibility: hidden;
        position: absolute;
        top: -200000px;
      }
      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
        aspect-ratio: 9/16;
      }

      svg {
        bottom: 3rem;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;

        @media (min-width: 425px) {
          bottom: 3.81rem;
        }
      }
      img {
        width: 23.75rem;
        height: 31.25rem;
        object-fit: cover;
      }
      .timer-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.5rem;
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: max-content max-content;
        align-content: center;
        justify-items: center;
        top: 0;

        .counter {
          font-weight: 700;
          font-size: 6rem;
          line-height: 7.56rem;
        }
      }
    }

    .model-selector-container {
      display: grid;
      grid-auto-flow: row;
      place-items: center;
      grid-gap: 2rem;
      align-self: end;
      span {
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.25rem;
      }

      .try-now-model-selector {
        display: grid;
        grid-template-columns: 6.25rem 6.25rem 6.25rem;
        align-items: center;
        justify-content: center;
        column-gap: 1.5rem;
        row-gap: 1.5rem;
        grid-row: 2;

        .model-image {
          height: 6.25rem;
          width: 6.25rem;
          cursor: pointer;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    .captured-image-viewer {
      position: absolute;
      height: 100%;
      width: 100%;
      overflow: hidden;

      .captured-image {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
        background-position: center;
        transform: scale(1);
      }

      .captured-image-actions {
        position: absolute;
        display: grid;
        grid-auto-flow: row;
        bottom: 1.625rem;
        right: 1rem;
        row-gap: 1rem;
        & * > svg,
        svg {
          cursor: pointer;
          border-radius: 50%;
        }
      }
    }

    .processed-image-container {
      position: absolute;

      height: 100%;

      width: 100%;
      display: grid;
      place-content: center;

      .image-comparer {
        width: 100%;
        height: 100%;
        position: relative;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: left;
        }

        .image-container {
          background: transparent;
          overflow: hidden;
        }

        .before-img {
          position: absolute;
          inset: 0;
        }
        .comparisonSlider {
          position: absolute;
          inset: 0;
          cursor: pointer;
          opacity: 0;
          width: 100%;
          height: 100%;
        }
        .comparisonSlider-line {
          position: absolute;
          inset: 0;
          width: 0.25rem;
          height: 100%;
          background-color: #ffffff;
          pointer-events: none;
          transform: translateX(-50%);
        }
        .comparisonSlider-button {
          position: absolute;
          width: 2rem;
          height: 2rem;
          background: transparent;
          background: url("../../assets/svg/grabHandleIcon.svg") center center
            no-repeat;
          cursor: pointer;
          pointer-events: none;
          inset: 0;
          top: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .try-now-photo-uploader {
      padding: 1rem;
      color: #ffffff;
      display: grid;
      grid-auto-flow: row;
      justify-items: center;
      row-gap: 1.625rem;

      & > p {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.25rem;
      }

      .instructions-container {
        display: grid;
        grid-template-columns: 6.25rem 1fr;
        row-gap: 0.75rem;
        column-gap: 1.25rem;
        align-items: center;

        img {
          width: 6.25rem;
          height: 3rem;

          @media (min-width: 425px) {
            height: 4rem;
          }
        }

        .step-description {
          display: grid;
          grid-auto-flow: row;
          row-gap: 0.25rem;
          & > p {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1rem;
            margin: 0;
          }
          div {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
        }
      }
      .metapix-button-icon {
        text-transform: uppercase;
        width: 100%;
      }
    }
  }
  .photo-upload-control {
    display: none !important;
  }
}
