.metapix-button-icon{
    background-color: transparent;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    height: 3rem;
    width: 15rem;
    text-align: center;
    border-radius: 5rem;
    font-size: 1rem;
    color: #FFFFFF;
    user-select: none;
    line-height: 1.25rem;
    display: grid;
    grid-template-columns: min-content max-content;
    justify-content: center;
    align-items: center;
    column-gap: 0.625rem;

    & > *{
        height: 1.25rem;
        width: 1.25rem;
    }
}