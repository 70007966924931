.category-section-container{
    grid-column: 1/-1;
    display: grid;
    background-color: #ffffff;

    @media (min-width: 1024px) {
        background-color: #ffffff;
        padding: 0 1rem;
    }
    .category-section{
        margin: 0.875rem 1rem;
        overflow: hidden;
       
        .categories-container{
            overflow: auto;
            grid-area: options;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @media (min-width: 1024px) {
            display: grid;
            grid-template-columns: max-content max-content;
            column-gap: 1rem;
            align-items: center;
            padding: 0.875rem 0;
            grid-template-areas: 'demo-store options';
            width: 73.5rem;
            margin: 0 auto;
        }

        .section-title{
            margin: 0;
            padding-bottom: 0.75rem;
            grid-area: demo-store;
            width: max-content;

            @media (min-width: 1024px) {
            padding-bottom: 0;
            }
        }
        .categories{
            display: grid;
            column-gap: 1rem;
            grid-auto-flow: column;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            @media (min-width: 425px) {
                width: max-content;
            }
        }
}
}
