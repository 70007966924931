html, body, #metapix-app{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  font-style: normal;
  font-size: 16px;
  color: #171717;
  background-color: #F5F5F5;
}
