.metapix-demo-store{
    display: grid;
    grid-template-rows: 5rem auto;
    position: relative;

    @media (max-width: 1023px) {
        grid-template-rows: auto auto;
    }

    .metapix-app-main{
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: auto auto;
        grid-template-areas:
            'category'
            'visualizer';
        
        @media (min-width: 425px) {
          row-gap: 1.5rem;
          grid-template-rows: auto auto auto;
        }
      
        @media (min-width: 1024px) {
          grid-template-rows: auto auto;
          column-gap: 1.25rem;
        }

        .try-now{
          grid-area: visualizer;
          display: grid;
          grid-template-rows: 31.25rem auto;
          grid-auto-flow: row;

          @media (min-width: 1024px) {
            grid-template-rows: auto;
            grid-template-columns: max-content 1fr;
            width: 73.5rem;
            justify-self: center;
            column-gap: 1rem;
          }
        }
      }
}

