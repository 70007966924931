.metapix-button-standard{
    background-color: #FFFFFF;
    cursor: pointer;
    border: 1px solid #404040;
    height: 2.25rem;
    line-height: 1.25rem;
    font-size: 0.875rem;
    width: max-content;
    border-radius: 5rem;
    user-select: none;
    padding: 0.5rem 1rem;
    text-transform: capitalize;

    &:hover, &.selected{
        background-color: #EC1763;
        color: #FFFFFF;
        border: none;
    }

    &.disabled{
        cursor: none;
        background-color:  #EC1763;
        color: #E5E5E5;

        &:hover{
            cursor: none;
            background-color: #EC1763;
            color: #E5E5E5;
            box-shadow: none;
        }
    }
}