.varient-detail{
    padding: 1.625rem;
    color: #171717;
    display: grid;
    grid-template-rows: auto auto auto auto auto auto auto;
    align-items: center;
    row-gap: 0.75rem;
    max-width: 48.75rem;
    justify-self: center;
    grid-template-areas:
        'tabHeader'
        'tabContent'
        'patternTabHeader'
        'patternTabContent'
        'headerText'
        'description'
        'actions';
    position: relative;
    
    @media (min-width: 1024px) {
        justify-self: start;
        grid-template-rows: max-content max-content max-content max-content max-content;
        background: #ffffff;
        border-radius: 1rem;
        grid-template-areas: none;
        grid-auto-flow: row;
    }
    .varient-overlay{
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 1rem;
        z-index: 1;
    }

    .varient-header-text{
        color: #171717;
        grid-area: headerText;

        @media (min-width: 1024px) {
            grid-area: unset;
        }

        & > *{
            margin: 0;
        }

        .title-text{
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.25rem;
            padding-bottom: 1rem;
        }

        .header-text{
            color: #404040;
            @media (min-width:1024px) {
                padding-bottom: 1.9rem;
            }
        }
    }

    .varient-tab-header{
        grid-area: tabHeader;

        &.pattern{
            grid-area: patternTabHeader;
            
            @media (min-width: 1024px) {
                grid-area: unset;
            }
        }

        @media (min-width: 1024px) {
            grid-area: unset;
        }

        .varient-tabs{
            font-size: 1rem;
            line-height: 1.25rem;
            text-transform: capitalize;
            display: grid;
            text-align: center;
            border-bottom: thin solid #E5E5E5;
            cursor: pointer;
            user-select: none;
    
            .tab{
                width: 100%;
                max-width: 7.3rem;
    
                &.selected{
                    border-bottom: 0.25rem solid #EC1763;
                    font-weight: 500;
                }
            }
        }
    }
    .varient-tab-content{
        width: 100%;
        overflow-x: hidden;
        grid-area: tabContent;

        &.pattern{
            grid-area: patternTabContent;

            @media (min-width: 1024px) {
                grid-area: unset;
            }
        }

        @media (min-width: 1024px) {
            grid-area: unset;
        }

        .varient-palette{
            align-items: center;
            height: 3.4rem;
            column-gap: 1rem;
            overflow-x: auto;
            padding: 0 0.5rem;

            @media (min-width:1024px) {
                column-gap: 1.5rem;
                grid-template-columns: repeat(auto-fit, 2.5rem) !important;
                row-gap: 1.5rem;
                padding: 0.5rem;
                height: 100%;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .palette-color{
                height: 2.5rem;
                width: 2.5rem;
                border-radius: 50%;
                position: relative;
                cursor: pointer;
                user-select: none;

                &:hover{
                    &::before{
                        border: thin solid #EC1763;
                    }
                }

                &.selected{
                    &::before{
                        border: thin solid #EC1763;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    height: 3rem;
                    width: 3rem;
                    top: -0.3rem;
                    left: -0.3rem;
                    border: thin solid #A3A3A3;
                    border-radius: 50%;

                    @media (min-width: 1024px) {
                        top: -0.35rem;
                    }
                }
            }
        }

        .pattern-palette{
            align-items: center;
            height: 5rem;
            column-gap: 1.625rem;
            overflow-x: auto;
            padding: 0 0.5rem;
            align-items: start;

            @media (min-width:1024px) {
                grid-template-columns: repeat(auto-fit, 3.75rem) !important;
                row-gap: 1.5rem;
                padding: 0.5rem;
                height: 5rem;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .palette-pattern{
                height: 2.5rem;
                width: 3.75rem;
                position: relative;
                cursor: pointer;
                user-select: none;
                // background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                border: thin solid #A3A3A3;
                border-radius: 0.25rem;

                &::before{
                    content: attr(data-pattern-title);
                    position: absolute;
                    font-size: 0.8rem;
                    width: max-content;
                    max-width: 3.75rem;
                    top: 2.5rem;
                }

                &:hover{
                    border: thin solid #EC1763;
                }

                &.selected{
                    border: thin solid #EC1763;
                }
            }
        }
    }
    
    .varient-description{
        line-height: 1.5rem;
        grid-area: description;
        color: #404040;

        @media (min-width: 1024px) {
            grid-area: unset;
        }
    }

    .store-varient-actions{
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        column-gap: 1rem;
        grid-area: actions;

        @media (min-width: 1024px) {
            grid-area: unset;
        }
    }
}
